import Vue from 'vue'
import App from './App.vue'
import router from './router'
import autoAnimate from "@formkit/auto-animate";

Vue.directive("auto-animate", {
  inserted: function (el, binding) {
    autoAnimate(el, binding.value);
  }
})
 
//Vue.use(VueFileAgent)

import Storage from 'vue-ls'

const options = {
  namespace: 'vuejs__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
}

Vue.use(Storage, options)

/*
import CountryFlag from 'vue-country-flag'

Vue.component('country-flag', CountryFlag)
*/

/*
import FlagIcon from 'vue-flag-icon'
Vue.use(FlagIcon)
*/
Vue.config.productionTip = false

const app = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
