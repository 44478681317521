<template>
  <div id="app">
    <router-view v-if="store.app.breakpoint"/>
  </div>
</template>

<script>
import {store} from './store'

var _paq = window._paq = window._paq || [];
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function() {
  var u="//solarcompanyberlin.de/matomo/";
  _paq.push(['setTrackerUrl', u+'matomo.php']);
  _paq.push(['setSiteId', '1']);
  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
})();


export default {
  components: {
  },
  data() {
    return {
      store: store.state
    }
  },
  async mounted() {
    store.setBreakpoint(window.innerWidth)
    window.addEventListener(
      'resize',
      store.setBreakpoint(window.innerWidth),
      false
    )
    let c = await store.checkWebP()
    if (c) this.store.app.imgFormat = 'webp'
  }
}
</script>

<style lang="stylus">
//@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;500;900&display=swap');
body {
  margin-top:0!important;
}
@font-face {
  font-family: 'Orator';
  font-style: normal;
  font-weight: normal;
  src: local(''),
       url('../public/static/fonts/Orator Std Medium.ttf') format('truetype')
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 100;
  src: local(''),
       url('../public/static/fonts/HelveticaNeue Thin.ttf') format('truetype')
}
@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 200;
  src: local(''),
       url('../public/static/fonts/HelveticaNeue Light.ttf') format('truetype')
}
@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('../public/static/fonts/HelveticaNeue Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../public/static/fonts/HelveticaNeue Medium.ttf') format('truetype')
}
@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('../public/static/fonts/HelveticaNeue Bold.ttf') format('truetype')
}


body {
  //font-family: 'Helvetica Neue'
  //font-weight:normal
}
html {
  -webkit-tap-highlight-color: transparent;
}
</style>
