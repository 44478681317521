import Vue from 'vue'
import VueRouter from 'vue-router'
//import HomeView from '../views/HomeView.vue'
//import Start from '../views/Start.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/card/:name?',
    name: 'card',
    component: () => import(/* webpackChunkName: "card" */ '../views/Card.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "homeview" */ '../views/HomeView.vue'),
    children: [
      {
        name: 'Start',
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '',
        props: true,
        component: () => import(/* webpackChunkName: "start" */ '../views/Start.vue'),
      },
      {
        name: 'Anfrage',
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '/anfrage',
        props: true,
        component: () => import(/* webpackChunkName: "start" */ '../views/Start.vue'),
      },
      {
        name: 'Leistungen',
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '/leistungen',
        props: true,
        component: () => import(/* webpackChunkName: "start" */ '../views/Start.vue'),
      },
      {
        name: 'Referenzen',
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '/referenzen',
        props: true,
        component: () => import(/* webpackChunkName: "start" */ '../views/Start.vue'),
      },
      {
        name: 'Kontakt',
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '/kontakt',
        props: true,
        component: () => import(/* webpackChunkName: "start" */ '../views/Start.vue'),
      },
      {
        name: 'Partner',
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '/partner',
        props: true,
        component: () => import(/* webpackChunkName: "start" */ '../views/Start.vue'),
      },
      {
        name: 'Karriere',
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '/karriere',
        component: () => import(/* webpackChunkName: "karriere" */ '../views/Karriere.vue')
      },
      {
        name: 'Datenschutz',
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '/datenschutz',
        component: () => import(/* webpackChunkName: "datenschutz" */ '../views/Datenschutz.vue')
      },
      {
        name: 'Bedarfsanalyse',
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '/bedarfsanalyse',
        component: () => import(/* webpackChunkName: "bedarfsanalyse" */ '../views/Bedarfsanalyse.vue')
      },
      {
        name: 'Netzanmeldung',
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '/netzanmeldung',
        component: () => import(/* webpackChunkName: "netzanmeldung" */ '../views/Netzanmeldung.vue')
      },
      {
        name: 'Personalfragebogen',
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '/scb_personalfragebogen',
        component: () => import(/* webpackChunkName: "netzanmeldung" */ '../views/personalfragebogen.vue')
      }
    ]
  }
]

let mode = 'history'
if (window.location.hostname.includes('translate.goog')) {
  mode = 'hash'
}

const router = new VueRouter({
  mode: mode,
  base: process.env.BASE_URL,
  routes
})

export default router
