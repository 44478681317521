import Vue from 'vue'
import Router from './router'
import axios from 'axios'

import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo, {
  container: "body",
  duration: 1000,
  easing: "ease",
  offset: -50,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

const state = new Vue({
  data() {
    return {
      state: {
        appStarted: false,
        introFinished: false,
        app: {
          apiUrl: process.env.VUE_APP_API,
          url: process.env.VUE_APP_URL,
          breakpoint: null,
          imgFormat: '',
          isTranslateMode: false
        },
        currentTranslation: 'de',
        translations: [
          {
            flag: 'de',
            gCode: 'de',
            url: process.env.VUE_APP_URL
          },
          {
            flag: 'gb',
            gCode: 'en'
          },
          {
            flag: 'ua',
            gCode: 'uk'
          },
          {
            flag: 'pl',
            gCode: 'pl'
          },
          {
            flag: 'ru',
            gCode: 'ru'
          },
          {
            flag: 'tr',
            gCode: 'tr'
          },
          {
            flag: 'sa',
            gCode: 'ar'
          }
        ],
        ui: {
          trigger1: false,
          startRendered: false
        },
        content: {
          website: null,
          karriere: null,
          referenzen: null,
          bedarfsanalyse: null,
          netzanmeldung: null,
          personalfragebogen: null
        },
        bedarfsanalyse: {
          formStructure: []
        },
        netzanmeldung: {
          formStructure: []
        },
        personalfragebogen: {
          formStructure: []
        }
      }
    }
  },
  computed: {
    
  },
  methods: {
    isInTranslateMode() {
      const hostname = window.location.hostname
      if (hostname.includes('translate.goog')) {
        this.state.app.isTranslateMode = true
        window.document.getElementById('gt-nvframe').style.display = 'none'
      } else {
        this.state.app.isTranslateMode = false
      }
    },
    async checkWebP() {
      return new Promise(res => {
        const webP = new Image()
        webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA'
        webP.onload = webP.onerror = () => {
            res(webP.height === 2)
        }
      })
    },
    async getContent() {
      try {
        let p1 = axios.get(this.state.app.apiUrl + '/items/website_content?fields=*.directus_files_id,Kontakt_Image.id,Referenzen_Liste.*,Partner_Liste.*,Social_Media.*,Statistiken_Liste.*')
        let p2 = axios.get(this.state.app.apiUrl + '/items/karriere_content')
        let p3 = axios.get(this.state.app.apiUrl + '/items/bedarfsanalyse_content')
        let promises = [p1,p2,p3]
        let result = await Promise.allSettled(promises)
        this.state.content.website = result[0].value?.data?.data
        this.state.content.karriere = result[1].value?.data?.data
        this.state.content.referenzen = this.state.content.website.Referenzen_Liste
        this.state.content.bedarfsanalyse = result[2].value?.data?.data
        console.log(this.state.content)
      } catch (err) {
        console.error(err)
      }
    },
    async getPersonalfragebogenContent() {
      let data = await axios.get(this.state.app.apiUrl + '/items/personalfragebogen_content')
      console.log("data", data)
      this.state.content.personalfragebogen = data.data.data
    },
    async navigateTo(name, target = '#start') {
      let lastName = Router.history.current.name
      if (lastName !== name) {
        await Router.push({ name: name, params: { target: target }})
        if ((lastName == 'Karriere' || lastName == 'Bedarfsanalyse' || lastName == 'Netzanmeldung' || name == 'Karriere') && target !== '#start') {
          await this.sleep(1000)
        }
      }
      if (!target) return
      let offset = -50
      if (target == '#anfrage') offset = -100
      if (!this.state.ui.startRendered) await this.sleep(500)
      this.$scrollTo(target, {
        offset: offset
      })
    },
    async sleep(t) {
      return new Promise((resolve) => setTimeout(resolve, t));
    },
    setBreakpoint(width) {
      if (width <= 640) {
        this.state.app.breakpoint = 'sm'
      } else if (width <= 768) {
        this.state.app.breakpoint = 'md'
      } else if (width <= 1024) {
        this.state.app.breakpoint = 'lg'
      } else if (width <= 1280) {
        this.state.app.breakpoint = 'xl'
      } else {
        this.state.app.breakpoint = '2xl'
      }
      console.log("set breakpoint: ", this.state.app.breakpoint)
    }
  }
})

export const store = state
